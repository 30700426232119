import React from 'react';
import * as DICTIONARY_MAIN_PAGE from '../../../constants/dictionary/main-page';
import styles from './styles.module.scss';
import global_styles from '../global.module.scss';

export default function AuthQuestions() {
    return (
        <div className={styles.main_info_block}>
            <div className={global_styles.new_main_container}>
                <div className={styles.main_advantages_block}>
                    <div className={styles.main_advantages_list}>
                        {DICTIONARY_MAIN_PAGE?.ADVANTAGES_LIST.map((item, key) => (
                            <div
                                className={styles.main_advantages_item}
                                key={key}
                                data-testid="advantages-item"
                            >
                                <img
                                    src={item.image}
                                    alt=""
                                    className={styles.main_advantages_item_img}
                                />
                                <div>
                                    <div className={styles.main_advantages_item_title}>
                                        {item.title}
                                    </div>
                                    <div
                                        className={styles.main_advantages_item_text}
                                        dangerouslySetInnerHTML={{ __html: item.description }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
