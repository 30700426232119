import React from 'react';
import styles from './styles.module.scss';
import * as DICTIONARY_MAIN_PAGE from '../../../constants/dictionary/main-page';

export default function AuthCategories() {
    return (
        <div className={styles.main_container}>
            <div className={styles.main_wrap}>
                <div className={styles.main_title}>{DICTIONARY_MAIN_PAGE.CATEGORIES_TITLE}</div>
                <div className={styles.main_row}>
                    <div className={styles.main_col}>
                        <a href="https://www.sofiadate.com/international-dating/">
                            International dating
                        </a>
                        <a href="https://www.sofiadate.com/professional-dating/">
                            Professional Dating
                        </a>
                        <a href="https://www.sofiadate.com/senior-dating/">Senior dating</a>
                        <a href="https://www.sofiadate.com/anonymous-dating/">Anonymous dating</a>
                    </div>
                    <div className={styles.main_col}>
                        <a href="https://www.sofiadate.com/dating-over-40/">Over 40 dating</a>
                        <a href="https://www.sofiadate.com/dating-over-50/">Over 50 dating</a>
                        <a href="https://www.sofiadate.com/dating-over-60/">Over 60 dating</a>
                        <a href="https://www.sofiadate.com/dating-a-widower/">Widower dating</a>
                    </div>
                    <div className={styles.main_col}>
                        <a href="https://www.sofiadate.com/childfree-dating/">Childfree dating</a>
                        <a href="https://www.sofiadate.com/single-parent-dating/">Parents dating</a>
                        <a href="https://www.sofiadate.com/divorced-dating/">Divorced dating</a>
                    </div>
                    <div className={styles.main_col}>
                        <a href="https://www.sofiadate.com/catholic-dating/">Catholic dating</a>
                        <a href="https://www.sofiadate.com/online-chat-dating/">
                            Online chat dating
                        </a>
                        {/*<a href="https://www.sofiadate.com/chat-room-flirting/">Flirty chat</a>*/}
                    </div>
                    <div className={styles.main_col}>
                        <a href="https://www.sofiadate.com/foreign-chat/">International chat</a>
                        <a href="https://www.sofiadate.com/christian-chat/">Christian chat</a>
                        {/*<a href="https://www.sofiadate.com/affair-dating/">Affair dating</a>*/}
                    </div>
                </div>
            </div>
        </div>
    );
}
