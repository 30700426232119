import { PurchaseActionTypes } from '../../constants/purchase';

export interface IPurchaseTokenItem {
    id: number;
    external_id: number;
    user_id: number;
    token: string;
    name: string;
    number: string;
    amount: number;
    description: string;
    status: number;
    default_card: number;
    payment_method: string;
    recurring_id: number;
    date_updated: string;
    date_added: string;
}

export interface IPurchaseMethodItem {
    method: string;
    status: boolean;
    default: boolean;
    isTechWork: boolean;
    order: number;
}

export enum CurrencyEnum {
    USD = 'USD',
    EUR = 'EUR',
    GBP = 'GBP',
}

export interface IPurchasePackageItem {
    id: number;
    credits: number;
    price: number;
    oldPrice: number;
    discount: number;
    siteID: number;
    category: number;
    title: string;
    status: number;
    first: number;
    vip: number;
    currency: CurrencyEnum;
}

export interface IPurchaseVat {
    id: number;
    countryName: string;
    countryCode: string;
    rate: number;
}

export interface IPurchaseActiveSubscribeData {
    id: number;
    credits: number;
    price: number;
    paymentMethod: string;
}

export interface IPurchaseActiveSubscribedItem {
    id: number;
    amount: number;
    credits: number;
    currency: string;
}

export interface IPurchaseState {
    purchaseList: IPurchasePackageItem[];
    vat: IPurchaseVat | null;
    isReverse: boolean | null;
    mostPopularPackageID: number | null;
    lastPurchaseList: IPurchasePackageItem[];
    isBuyingPastabank: boolean;
    isFirstPurchase: number | null;
    count: number | null;
    isLoadingBankRes: boolean;
    isPaymentFailed: boolean;
    packageBuyCount: number | null;

    lastBuyPackage: IPurchasePackageItem | null;
    activePaymentMethodList: string;
    paymentMethodList: IPurchaseMethodItem[];

    tokenList: IPurchaseTokenItem[];
    errorMassage: string;

    isAddCard: boolean;

    activeSubscribeStatus: boolean;
    activeSubscribeData: IPurchaseActiveSubscribeData | null;
    activeSubscribePaymentMethod: string;
    activeSubscribeListInfo: IPurchaseActiveSubscribedItem[];
    isOpenSuccessSubscribeModal: boolean;
    isOpenChangeSubscribeModal: boolean;
    isOpenSuccessChangeSubscribeModal: boolean;
    isChangeSubscription: boolean;
    prevSubscriptionCreditPack: number | null;

    activePackage: IPurchasePackageItem | null;
    activePaymentMethod: string;

    isPaymentPastaFailed: boolean;
}

export interface ISetIsAddCardAction {
    type: typeof PurchaseActionTypes.SET_IS_ADD_CARD;
    status: boolean;
}

export interface ISetTokenListAction {
    type: typeof PurchaseActionTypes.SET_TOKEN_LIST;
    tokenList: IPurchaseTokenItem[];
}

export interface IAddLastBuyPackageAction {
    type: typeof PurchaseActionTypes.ADD_LAST_BUY_PACKAGE;
    lastBuyPackage: IPurchasePackageItem;
}

export interface IRemoveLastBuyPackageAction {
    type: typeof PurchaseActionTypes.REMOVE_LAST_BUY_PACKAGE;
}

export interface IGetPurchaseListAction {
    type: typeof PurchaseActionTypes.GET_PURCHASE_LIST;
    data: any;
    vat: IPurchaseVat;
    isReverse: boolean;
    mostPopularPackageID: number | null;
    isFirstPurchase: number | null;
    count: number;
    activePaymentMethodList: string;
    paymentMethodList: IPurchaseMethodItem[];
}

export interface IRemovePurchaseListAction {
    type: typeof PurchaseActionTypes.REMOVE_PURCHASE_LIST;
}

export interface IInitSubscribeStatusAction {
    type: typeof PurchaseActionTypes.INIT_SUBSCRIBE_STATUS;
    activeSubscribeStatus: boolean;
    activeSubscribeData: IPurchaseActiveSubscribeData | null;
}

export interface ISetActiveSubscribeAction {
    type: typeof PurchaseActionTypes.SET_ACTIVE_SUBSCRIBE;
    status: boolean;
}

export interface ICancelActiveSubscribeAction {
    type: typeof PurchaseActionTypes.CANCEL_ACTIVE_SUBSCRIBE;
}

export interface IGetActiveSubscribeListInfoAction {
    type: typeof PurchaseActionTypes.GET_ACTIVE_SUBSCRIBE_LIST_INFO;
    data: any;
}

export interface IClearActiveSubscribeListInfoAction {
    type: typeof PurchaseActionTypes.CLEAR_ACTIVE_SUBSCRIBE_LIST_INFO;
}

export interface ISetSuccessSubscribeModalAction {
    type: typeof PurchaseActionTypes.SET_SUCCESS_SUBSCRIBE_MODAL;
    status: boolean;
}

export interface ISetChangeSubscribeModalAction {
    type: typeof PurchaseActionTypes.SET_CHANGE_SUBSCRIBE_MODAL;
    status: boolean;
}

export interface ISetSuccessChangeSubscribeModalAction {
    type: typeof PurchaseActionTypes.SET_SUCCESS_CHANGE_SUBSCRIBE_MODAL;
    status: boolean;
}

export interface ISetIsChangeSubscriptionAction {
    type: typeof PurchaseActionTypes.SET_IS_CHANGE_SUBSCRIPTION;
    status: boolean;
    credits: number | null;
}

export interface ISetIsBuyingPastabankAction {
    type: typeof PurchaseActionTypes.SET_IS_BUYING_PASTABANK;
    status: boolean;
}

export interface ISetIsPaymentFailedAction {
    type: typeof PurchaseActionTypes.SET_IS_PAYMENT_FAILED;
    status: boolean;
    packageBuyCount: number | null;
}

export interface ISetIsPastaPaymentFailedAction {
    type: typeof PurchaseActionTypes.SET_IS_PASTA_PAYMENT_FAILED;
    status: boolean;
    errorMassage: string;
}

export interface ISetActivePackageAction {
    type: typeof PurchaseActionTypes.SET_ACTIVE_PACKAGE;
    activePackage: IPurchasePackageItem;
}

export interface ISetActivePaymentMethodAction {
    type: typeof PurchaseActionTypes.SET_ACTIVE_PAYMENT_METHOD;
    activePaymentMethod: string;
}

export type PurchaseActions =
    | ISetIsAddCardAction
    | ISetTokenListAction
    | IAddLastBuyPackageAction
    | IRemoveLastBuyPackageAction
    | IGetPurchaseListAction
    | IRemovePurchaseListAction
    | IInitSubscribeStatusAction
    | ISetActiveSubscribeAction
    | ICancelActiveSubscribeAction
    | IGetActiveSubscribeListInfoAction
    | IClearActiveSubscribeListInfoAction
    // | ISetSubscribeExtraStatusAction // removed
    // | IGetSubscribeExtraPlanMethodAction // removed
    | ISetSuccessSubscribeModalAction
    | ISetChangeSubscribeModalAction
    | ISetSuccessChangeSubscribeModalAction
    | ISetIsChangeSubscriptionAction
    | ISetIsBuyingPastabankAction
    | ISetIsPaymentFailedAction
    | ISetIsPastaPaymentFailedAction
    | ISetActivePackageAction
    | ISetActivePaymentMethodAction;
