import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import global_styles from '../global.module.scss';
import Google from '../../common/Social/Google';
import SITE from '../../../config/site';
import Logotypes from '../../../img/logotypes';
import { IAuthHeader } from '../index.interface';
import { isHolidayActive } from '../../../constants/holiday';

export default function AuthHeader({
    openSupportModal,
    openSignInBlock,
    postResponseGoogle,
}: IAuthHeader) {
    const [isScroll, setIsScroll] = useState(false);
    const activeHoliday = isHolidayActive();
    const logoLightSrc =
        activeHoliday && Logotypes.images[`logo_${activeHoliday?.name}_light.svg`]
            ? Logotypes.images[`logo_${activeHoliday?.name}_light.svg`]
            : Logotypes.images[`logo_light.svg`];

    const logoDefaultSrc =
        activeHoliday && Logotypes.images[`logo_${activeHoliday?.name}.svg`]
            ? Logotypes.images[`logo_${activeHoliday?.name}.svg`]
            : Logotypes.images[`logo.svg`];

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY === 0) setIsScroll(false);
            else setIsScroll(true);
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className={`${styles.main_header_wrap} ${isScroll ? styles.scroll : ''}`}>
            <div className={global_styles.new_main_container}>
                <div className={styles.main_header}>
                    <div className={styles.main_header_left}></div>
                    <div className={styles.main_header_logo}>
                        <img
                            className={styles.main_header_logo_img_light}
                            src={logoLightSrc}
                            width="118"
                            height="82"
                            alt={SITE.name}
                        />
                        <img
                            className={styles.main_header_logo_img_general}
                            src={logoDefaultSrc}
                            width="118"
                            height="82"
                            alt={SITE.name}
                        />
                    </div>

                    <div className={styles.header_buttons}>
                        {postResponseGoogle && <Google postResponseGoogle={postResponseGoogle} />}
                        <button
                            type="button"
                            title="Support"
                            className={styles.header_button_support}
                            onClick={openSupportModal}
                        >
                            Support
                        </button>
                        <button
                            type="button"
                            className={styles.header_button_login}
                            onClick={openSignInBlock}
                            data-testid="sign-in-btn"
                        >
                            <span className={styles.header_button_login_text}>Sign in</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
