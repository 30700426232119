import React from 'react';
import * as DICTIONARY_MAIN_PAGE from '../../../constants/dictionary/main-page';
import * as MENU from '../../../constants/menu';
import styles from './styles.module.scss';
import global_styles from '../global.module.scss';
import SITE from '../../../config/site';
import Logotypes from '../../../img/logotypes';
import { IAuthFooter } from '../index.interface';
import image_payment_mac from '../../../img/payment_mac.svg';
import image_payment_ssl from '../../../img/payment_ssl.svg';
import image_payment_mastercard from '../../../img/payment_mastercard.svg';
import image_payment_visa from '../../../img/payment_visa.svg';
import image_payment_pci from '../../../img/payment_pci.svg';
import image_instagram from '../../../img/instagram_icon.svg';
import image_facebook from '../../../img/facebook_icon.svg';
import image_twitter from '../../../img/twitter_icon.svg';
import image_youtube from '../../../img/youtube_icon.svg';
import { isHolidayActive } from '../../../constants/holiday';

export default function AuthFooter({ openTermModal, openAffiliatsModal }: IAuthFooter) {
    const activeHoliday = isHolidayActive();
    const logoLightSrc =
        activeHoliday && Logotypes.images[`logo_${activeHoliday?.name}_light.svg`]
            ? Logotypes.images[`logo_${activeHoliday?.name}_light.svg`]
            : Logotypes.images[`logo_light.svg`];

    return (
        <footer className={styles.main_footer}>
            <div className={global_styles.new_main_container}>
                <div className={styles.main_footer_top}>
                    <div className={styles.main_footer_col_1}>
                        <div className={styles.main_footer_logo}>
                            <img src={logoLightSrc} width="135" height="64" alt={SITE.brand} />
                        </div>
                        <div className={styles.main_footer_copyright}>
                            <small>Astrasoft Projects Ltd</small>
                            <small>Ifigeneias 14, 3036, Limassol, Cyprus</small>
                            <small>Registration number HE 409810</small>
                            <small>
                                E-mail:{' '}
                                <a href="mailto:support@sofiadate.com">support@sofiadate.com</a>
                            </small>
                            <small className={styles.age_restriction}>
                                You must be 18+ to use this site.
                            </small>
                        </div>
                    </div>
                    <div className={styles.main_footer_col_2}>
                        <div className={styles.main_footer_c1_images} data-testid="c1-images">
                            <img src={image_payment_mac} width="92" height="36" alt="mac payment" />
                            <img src={image_payment_ssl} width="91" height="41" alt="ssl payment" />
                            <img
                                src={image_payment_mastercard}
                                width="73"
                                height="51"
                                alt="mastercard payment"
                            />
                            <img
                                src={image_payment_visa}
                                width="80"
                                height="36"
                                alt="visa payment"
                            />
                            <img src={image_payment_pci} width="80" height="40" alt="pci payment" />
                        </div>
                        <div className={styles.main_footer_social} data-testid="social-logos">
                            <a
                                href={DICTIONARY_MAIN_PAGE.LINK_YOUTUBE}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <div>
                                    <img src={image_youtube} alt="" />
                                </div>
                            </a>
                            <a
                                href={DICTIONARY_MAIN_PAGE.LINK_FACEBOOK}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <div>
                                    <img src={image_facebook} alt="" />
                                </div>
                            </a>
                            <a
                                href={DICTIONARY_MAIN_PAGE.LINK_INSTAGRAM}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <div>
                                    <img src={image_instagram} alt="" />
                                </div>
                            </a>
                            <a
                                href={DICTIONARY_MAIN_PAGE.LINK_TWITTER}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <div>
                                    <img src={image_twitter} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className={styles.main_footer_bottom}>
                    <div className={styles.main_footer_bottom_links}>
                        <div>
                            <div onClick={() => openTermModal(MENU.LINK_COOKIE)}>
                                {DICTIONARY_MAIN_PAGE.FOOTER_COOKIE}
                            </div>
                        </div>
                        <div>
                            <div onClick={() => openTermModal(MENU.LINK_PRIVACY)}>
                                {DICTIONARY_MAIN_PAGE.FOOTER_PRIVACY_POLICY}
                            </div>
                        </div>
                        <div>
                            <div onClick={() => openTermModal(MENU.LINK_TERMS)}>
                                {DICTIONARY_MAIN_PAGE.FOOTER_TERMS}
                            </div>
                        </div>
                        <div>
                            <div onClick={() => openTermModal(MENU.LINK_RETURN_REFUND)}>
                                {DICTIONARY_MAIN_PAGE.FOOTER_REFUND}
                            </div>
                        </div>
                        <div>
                            <div onClick={openAffiliatsModal}>
                                {DICTIONARY_MAIN_PAGE.FOOTER_AFFILIATES}
                            </div>
                        </div>
                        <div>
                            <div onClick={() => openTermModal(MENU.LINK_STANDARDS)}>
                                {DICTIONARY_MAIN_PAGE.FOOTER_STANDARTS}
                            </div>
                        </div>
                        <div>
                            <div onClick={() => openTermModal(MENU.LINK_AUTOMATIC_TOP_UP)}>
                                {DICTIONARY_MAIN_PAGE.FOOTER_AUTOMATIC_TOP_UP}
                            </div>
                        </div>
                        <div>
                            <div onClick={() => openTermModal(MENU.LINK_DISPUTE_MANAGEMENT)}>
                                {DICTIONARY_MAIN_PAGE.FOOTER_DISPUTE_RESOLUTION}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
