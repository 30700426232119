import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendBinomoFromStore } from '../../services/binomo';
import * as USER from '../../constants/user';
import { sendAfficeFromStore } from '../../services/affise';
import useTransition from '../transition/useTransition';
import * as alertAction from '../../store/actions/alert';
import * as userAction from '../../store/actions/user';
import { getUrlParameter } from '../../utils/url-utils';
import useGoogleDataLayer from '../event/useGoogleDataLayer';
import MarketingApi from '../../services/api/MarketingApi';
import useFBConversion from '../event/useFBConversion';
import { RootState } from '../../index';

export default function useCheckIsConfirmEmail() {
    const userState = useSelector((state: RootState) => state.user);
    const transition = useTransition();
    const dispatch = useDispatch();
    const { pushEventConfirmedEmail } = useGoogleDataLayer();
    const { sentFBConfirmEmailConversion } = useFBConversion();

    useEffect(() => {
        if (userState?.info?.external_id && window?.location?.search.includes('confirm_email=1')) {
            sendBinomoFromStore({
                externalID: userState?.info?.external_id,
                siteID: userState?.info?.site_id,
                status: USER.BINOMO_CONFIRM_EMAIL,
                clickid: userState?.clickid,
                utm_aff_hash: userState?.utm_aff_hash,
                ghost: userState?.info?.ghost,
                email: userState?.info?.email,
            });
            sendAfficeFromStore({
                status: USER.AFFISE_CONFIRM_EMAIL,
                info: userState?.info,
            });

            const confirmBonus = getUrlParameter('confirm_bonus') || false;
            const bonusMessage = +confirmBonus ? ` and added ${confirmBonus} bonus credits` : '';
            const message = 'You’ve successfully confirmed your email address' + bonusMessage;
            dispatch(alertAction.setMessage({ message, title: 'Email confirmed' }));

            if (+userState?.info?.email_confirmed === 0) {
                dispatch(userAction.updateUserInfo({ ...userState?.info, email_confirmed: 1 }));
            }

            if (
                !!+userState?.info?.is_approve_phone &&
                !!userState?.info?.real_name?.length &&
                !+userState?.info?.is_verified
            ) {
                dispatch(userAction.setIsUserVerified());
            }

            new MarketingApi().postCheckExistEvent('confirmed_email').then((resp) => {
                if (resp?.status && resp?.response?.check) {
                    // Push DataLayer for FB
                    pushEventConfirmedEmail();

                    if (userState?.tail?.includes('utm_age=45')) {
                        sentFBConfirmEmailConversion();
                    }
                }
            });

            transition.addTail(window?.location?.search?.replace(`confirm_email=1`, ''));
        }
    }, [userState.info]);
}
