// HIDDEN BLOCKS BY SITE ID
export const HIDDEN_VIDEO_SHOW = [2, 4, 9, 10, 11, 12, 13, 14, 15, 16, 19, 20, 21, 22];
export const HIDDEN_TUTORIAL_PAGE = [9, 10];
export const HIDDEN_GIFT = [2, 9, 10, 15, 16, 19, 20, 21, 22];
export const NOT_HIDE_STRING_STAR = [2, 9, 10, 15, 16, 19, 20, 21, 22];
export const HIDDEN_CONTACT_REQUEST = [2, 9, 10, 15, 16, 19, 20, 21, 22];
export const HIDDEN_MEETING_REQUEST = [2, 9, 10, 15, 16, 19, 20, 21, 22];

//Added LEGAL_ENTITY_INFO to pages
export const SITE_SEMPITERNA = [7, 8, 9, 10, 11, 12, 20, 21, 22];
export const SITE_ASTRASOFT = [1, 2, 5, 6, 13, 14, 17, 18, 19, 29, 31];
export const SITE_KRISTALDIGITAL = [15, 16, 24, 25, 26, 27, 28, 30];
export const SITE_BROADSTREAM = [23];
export const INFO_SEMPITERNA =
    'SEMPITERNA DIGITAL LIMITED, Code: ΗΕ 440404, address: ELIAS VENEZI, 2A, ATHIENITIS STROVOLOS PARK, 4th Floor, Office 402, Strovolos, CY-01, 2042, CY';
export const INFO_ASTRASOFT =
    'ASTRASOFT PROJECTS LTD, Reg. #ΗΕ 409810, Ifigeneias 14, 3036, Limassol, Cyprus';
export const INFO_KRISTALDIGITAL =
    'KRISTALDIGITAL OÜ, Reg No 16742040, Vesivärava 50-201, 10152 Tallinn Estonia';

export const INFO_BROADSTREAM =
    'BROADSTREAM OU, HARJU MAAKOND, TALLINN, KESKLINNA LINNAOSA, NARVA MNT 7-543, 10117, COMPANY NUMBER 16893974';

export const LATINA_SITES = [11, 13, 16];
export const ASIA_SITES = [12, 14, 15];

//MIRRORS with post page (new logic) and nickname\username
export const WITH_NICKNAME = [23];
export const SUCCESS_COMPLAIN_MODAL = [23];

//MIRRORS with new Zendesk Widget
export const NEW_ZENDESK_WIDGET = [30];
