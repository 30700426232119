import React from 'react';
import GirlSimpleItem from '../../../like/LikeItemSimple';
import { useSelector } from 'react-redux';
import styles from '../styles.module.scss';
import { RootState } from '../../../../index';
import SearchCrossBanner from '../../SearchCrossBanner';

export default function DefaultList() {
    const searchState = useSelector((state: RootState) => state.search);
    return (
        <ul className={styles.search_result_wrap}>
            {searchState?.list?.map((item, key) => {
                if ('target_site_id' in item) {
                    return <SearchCrossBanner key={key} {...item} />;
                }
                return <GirlSimpleItem key={key} profile={item} />;
            })}
        </ul>
    );
}
