import React from 'react';
import { Animated } from 'react-animated-css';
import { getImageLink } from '../../../services/methods/search/images';
import * as IMAGES_SIZES from '../../../constants/images';
import useHandleLikeHer from '../../../hooks/likeher/useHandleLikeHer';
import useHandleActiveBlocks from '../../../hooks/user/useHandleActiveBlocks';
import { useSelector } from 'react-redux';
import EmptyResult from '../../common/EmptyResult';
import * as SEX from '../../../constants/sex';
import LoaderGif from '../../common/LoaderGif';
import { RootState } from '../../../index';
import IconLetters from '../../Icons/IconLetters';
import IconVideos from '../../Icons/IconVideos';
import IconProfileChat from '../../Icons/IconProfileChat';
import IconArrowRight from '../../Icons/IconArrowRight';
import IconLikeHerHeart from '../../Icons/IconLikeHerHeart';
import { isHolidayActive } from '../../../constants/holiday';
import VerifiedProfile from '../../common/VerifiedProfile';
import LikeCrossBanner from '../LikeCrossBanner';

interface ILikeProfile {
    setDefaultFilterValue: () => void;
}

export default function LikeProfile({ setDefaultFilterValue }: ILikeProfile) {
    const likesState = useSelector((state: RootState) => state.likes);
    const userState = useSelector((state: RootState) => state.user);
    const {
        redirectVideoProfile,
        redirectProfile,
        onSetLike,
        openLetter,
        openChat,
        incrementSelectedIndex,
        decrementSelectedIndexGallery,
        incrementSelectedIndexGallery,
        setIsLoadFirstImg,
        prevRef,
        nextRef,
        womanInfo,
        isLoadFirstImg,
        countPrivateImage,
        animationVisible,
        touchMove,
        gallery,
        galleryIndex,
        isEmpty,
    } = useHandleLikeHer();
    const handleActiveBlocks = useHandleActiveBlocks();
    const activeHoliday = isHolidayActive();
    const isWomanInfo = womanInfo && 'external_id' in womanInfo;
    if (womanInfo && !isWomanInfo && !isEmpty) {
        return <LikeCrossBanner {...womanInfo} />;
    }
    const isVideo = !!(handleActiveBlocks.isVideoShow && isWomanInfo && !!+womanInfo?.has_video);
    if (likesState?.isLoading) return <LoaderGif />;
    if (!womanInfo && isEmpty)
        return <EmptyResult type={'like'} setDefaultFilterValue={setDefaultFilterValue} />;
    return !gallery?.length ? (
        isEmpty ? (
            <EmptyResult type={'like'} setDefaultFilterValue={setDefaultFilterValue} />
        ) : (
            <div />
        )
    ) : (
        <div
            className="temp_wrap"
            onTouchStart={touchMove.handleTouchStart}
            onTouchMove={(e) =>
                touchMove.handleTouchMove(
                    e,
                    () => incrementSelectedIndexGallery(e),
                    () => decrementSelectedIndexGallery(e),
                )
            }
        >
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Animated
                animationOut={'zoomOutLeft'}
                animationOutDuration={590}
                isVisible={animationVisible}
                animationIn={'fadeInRight'}
                animationInDuration={350}
            >
                <div className={`likeher_page_wrap ${activeHoliday && activeHoliday?.name}`}>
                    <div className="likeher_page_top">
                        <div className="likeher_page_photos">
                            {window.innerWidth > 600 &&
                                gallery
                                    .filter((item) => item?.link)
                                    .map((item, key) => (
                                        <div
                                            className={`likeher_page_small_photo small_photo_left`}
                                            key={key}
                                            style={
                                                galleryIndex === 0
                                                    ? gallery?.length - 1 === +key
                                                        ? { display: 'block' }
                                                        : { display: 'none' }
                                                    : +galleryIndex - 1 === +key
                                                      ? { display: 'block' }
                                                      : { display: 'none' }
                                            }
                                        >
                                            <img
                                                src={getImageLink(item?.link, IMAGES_SIZES.SIZE_M)}
                                                className={`likeher_page_small_photo_item ${!+item?.free && 'blur_unpaid'}`}
                                                alt=""
                                            />
                                        </div>
                                    ))}

                            {!isLoadFirstImg && window.innerWidth <= 600 ? (
                                <div className="likeher_page_big_photo">
                                    <img
                                        src={getImageLink(gallery[0]?.link, IMAGES_SIZES.SIZE_M)}
                                        onLoad={() => setIsLoadFirstImg(true)}
                                        className="likeher_page_big_photo_item"
                                        alt=""
                                    />
                                </div>
                            ) : (
                                gallery
                                    .filter((item) => item?.link)
                                    .map((item, key) =>
                                        !+item?.free ? (
                                            <div
                                                className="likeher_page_big_photo white"
                                                key={key}
                                                style={
                                                    +galleryIndex === +key
                                                        ? { display: 'block' }
                                                        : { display: 'none' }
                                                }
                                            >
                                                <div
                                                    className="popup_likeher_photo_unpaid_warning gallery like_her"
                                                    style={
                                                        +galleryIndex === +key
                                                            ? { display: 'block' }
                                                            : { display: 'none' }
                                                    }
                                                >
                                                    <div className="popup_likeher_photo_unpaid_warning_text">
                                                        <span>{`This member has ${+countPrivateImage > 0 ? countPrivateImage : ''} other private photos`}</span>
                                                        {handleActiveBlocks.isSearchV3
                                                            ? 'To view member’s private photos, start chatting'
                                                            : SEX.SEX_TEXT[
                                                                  userState?.info
                                                                      ?.sex_text as keyof typeof SEX.SEX_TEXT
                                                              ].GALLERY_MODAL_START_CHATTING_TITLE}
                                                    </div>
                                                </div>
                                                <img
                                                    className="likeher_page_big_photo_item blur_unpaid"
                                                    src={getImageLink(
                                                        item?.link,
                                                        IMAGES_SIZES.SIZE_M,
                                                    )}
                                                    alt=""
                                                />
                                            </div>
                                        ) : (
                                            <div
                                                className="likeher_page_big_photo"
                                                key={key}
                                                style={
                                                    +galleryIndex === +key
                                                        ? { display: 'block' }
                                                        : { display: 'none' }
                                                }
                                            >
                                                <img
                                                    className="likeher_page_big_photo_item"
                                                    src={getImageLink(
                                                        item?.link,
                                                        IMAGES_SIZES.SIZE_M,
                                                    )}
                                                    alt=""
                                                />
                                            </div>
                                        ),
                                    )
                            )}

                            {window.innerWidth > 600 &&
                                gallery
                                    .filter((item) => item?.link)
                                    .map((item, key) => (
                                        <div
                                            className={`likeher_page_small_photo small_photo_right`}
                                            key={key}
                                            style={
                                                galleryIndex === gallery?.length - 1
                                                    ? 0 === +key
                                                        ? { display: 'block' }
                                                        : { display: 'none' }
                                                    : +galleryIndex + 1 === +key
                                                      ? { display: 'block' }
                                                      : { display: 'none' }
                                            }
                                        >
                                            <img
                                                src={getImageLink(item?.link, IMAGES_SIZES.SIZE_M)}
                                                className={`likeher_page_small_photo_item ${!+item?.free && 'blur_unpaid'}`}
                                                alt=""
                                            />
                                        </div>
                                    ))}

                            <div
                                className="likeher_page_photos_arrow left_arrow"
                                onClick={decrementSelectedIndexGallery}
                                ref={prevRef}
                            >
                                <img src="/img/c3lh-list-prev-btn.svg" alt="" />
                            </div>
                            <div
                                className="likeher_page_photos_arrow right_arrow"
                                onClick={incrementSelectedIndexGallery}
                                ref={nextRef}
                            >
                                <img src="/img/c3lh-list-next-btn.svg" alt="" />
                            </div>
                        </div>

                        {isWomanInfo && !!womanInfo?.online && (
                            <div className="likeher_page_top_status">I’m online now</div>
                        )}
                    </div>
                    <div
                        className="likeher_page_middle"
                        onClick={(e) => window.innerWidth <= 600 && redirectProfile(e)}
                    >
                        {isWomanInfo && (
                            <div className="likeher_page_profile_info">
                                <div className="likeher_page_profile_name">
                                    {`${womanInfo?.name ?? ''}, ${womanInfo?.age ?? ''}`}
                                    <VerifiedProfile />
                                </div>
                                <div className="likeher_page_profile_info_bottom">
                                    <div className="likeher_page_profile_place">
                                        <img
                                            src={`/assets/flags/${womanInfo?.country_code?.toLowerCase()}.jpg`}
                                            alt=""
                                        />
                                        <span>{womanInfo?.city_name ?? ''}</span>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div
                            className={`${isVideo ? 'likeher_page_profile_buttons_with_video' : ''} likeher_page_profile_buttons`}
                        >
                            <div className="likeher_page_profile_chat_btn" onClick={openChat}>
                                <div className="likeher_page_profile_chat_btn_img" />
                                <span>
                                    Chat<span> Now</span>
                                </span>
                            </div>
                            <div
                                className="likeher_page_profile_letter_btn likeher_page_profile_btn"
                                onClick={openLetter}
                            >
                                <IconLetters />
                                <span>Letter</span>
                            </div>

                            {isVideo && (
                                <div
                                    className="likeher_page_profile_video_btn likeher_page_profile_btn"
                                    onClick={redirectVideoProfile}
                                >
                                    <IconVideos />
                                    <span>Video</span>
                                </div>
                            )}

                            <div
                                className="likeher_page_profile_profile_btn likeher_page_profile_btn"
                                onClick={redirectProfile}
                            >
                                <IconProfileChat />
                                <span>Profile</span>
                            </div>
                        </div>
                    </div>
                    <div className="likeher_page_bottom">
                        <div
                            className="likeher_page_next_btn likeher_page_control_btn"
                            onClick={incrementSelectedIndex}
                        >
                            <IconArrowRight />
                            <span>
                                {
                                    SEX.SEX_TEXT[
                                        userState?.info?.sex_text as keyof typeof SEX.SEX_TEXT
                                    ].LIKEHER_NEXT_BTN
                                }
                            </span>
                        </div>
                        {isWomanInfo && (
                            <div
                                className="likeher_page_like_btn likeher_page_control_btn"
                                onClick={() => onSetLike(womanInfo?.external_id ?? 0)}
                            >
                                <IconLikeHerHeart />
                                <span>
                                    {
                                        SEX.SEX_TEXT[
                                            userState?.info?.sex_text as keyof typeof SEX.SEX_TEXT
                                        ].LIKE_SIDE_MENU_BTN
                                    }
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </Animated>
        </div>
    );
}
