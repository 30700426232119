import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../styles.module.scss';
import OnlineNowItem from '../OnlineNowItem';
import * as modalsAction from '../../../store/actions/modals';
import useInitOnlineWoman from '../../../hooks/search/useInitOnlineWoman';
import useGetWinkList from '../../../hooks/wink/useGetWinkList';
import VIPListBanner from '../../common/VIP/VIPListBanner';
import { RootState } from '../../../index';
import { IWomanGalleryImage } from '../../../store/actions/modals/index.interface';
import OnlineNowCrossBanner from '../OnlineNowCrossBanner';

export default function OnlineNowWithBannerList() {
    const onlineNowState = useSelector((state: RootState) => state.onlineNow);

    const dispatch = useDispatch();
    const { sayHelloList } = useGetWinkList({});
    const { askVideo, openChat, openLetter } = useInitOnlineWoman();

    const openGalleryModal = (externalID: number, images: IWomanGalleryImage[] = [], index = 0) => {
        dispatch(modalsAction.openWomanGalleryModal(externalID, images, index));
    };

    return (
        <div className={styles.online_now_list}>
            {onlineNowState?.list?.length &&
                onlineNowState?.list?.slice(0, 1)?.map((item, key) => {
                    if (item && 'target_site_id' in item) {
                        return <OnlineNowCrossBanner key={key} {...item} />;
                    }
                    return (
                        <OnlineNowItem
                            sayHelloList={sayHelloList}
                            key={`item_${key}_${item.external_id ?? key}`}
                            profile={item}
                            openGalleryModal={openGalleryModal}
                            askVideo={askVideo}
                            openChat={openChat}
                            openLetter={openLetter}
                        />
                    );
                })}
            <VIPListBanner />
            {onlineNowState?.list?.length > 1 &&
                onlineNowState?.list?.slice(1)?.map((item, key) => {
                    if (item && 'target_site_id' in item) {
                        return <OnlineNowCrossBanner key={key} {...item} />;
                    }
                    return (
                        <OnlineNowItem
                            sayHelloList={sayHelloList}
                            key={`item_${key}_${item.external_id ?? key}`}
                            profile={item}
                            openGalleryModal={openGalleryModal}
                            askVideo={askVideo}
                            openChat={openChat}
                            openLetter={openLetter}
                        />
                    );
                })}
        </div>
    );
}
