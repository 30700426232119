import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import * as DICTIONARY_MAIN_PAGE from '../../../../constants/dictionary/main-page';
import * as REGISTER from '../../../../constants/register';
import * as MENU from '../../../../constants/menu';
import * as LENGTH from '../../../../constants/length';
import {
    checkEmailPattern,
    getArrayOfNumbersYear,
    getDaysAmount,
} from '../../../../services/methods';
import styles from './styles.module.scss';
import useOutsideClicker from '../../../../hooks/dom/useOutsideClicker';
import { checkErrorField } from '../../../../services/methods/register';
import SITE from '../../../../config/site';
import { IForm } from '../../index.interface';

export default function Form({
    formDataRegister = {
        name: '',
        email: '',
        password: '',
        bDay: 0,
        bMonth: 0,
        bYear: 0,
        gender: 'Man',
        agree: false,
    },
    setFormDataRegister,
    onRegister,
    onBirthdayChange,
    openSignInBlock,
    openTermModal,
    errorFields,
    filterErrorFields,
    hasCaptcha,
    closeRegisterModal,
    modalIsActiveRegister,
    recaptchaRef,
}: IForm) {
    const [openBoxM, setOpenBoxM] = useState(false);
    const [openBoxD, setOpenBoxD] = useState(false);
    const [openBoxY, setOpenBoxY] = useState(false);
    const [openLookingBox, setOpenLookingBox] = useState(false);
    const [openEmailList, setOpenEmailList] = useState(false);
    const [isPasswordHidden, setPasswordHidden] = useState(true);

    const selectRefM = useRef<HTMLDivElement | null>(null);
    const selectRefD = useRef<HTMLDivElement | null>(null);
    const selectRefY = useRef<HTMLDivElement | null>(null);
    const selectLookingBoxRef = useRef<HTMLDivElement | null>(null);
    const selectEmailRef = useRef<HTMLDivElement | null>(null);

    useOutsideClicker(selectRefM, setOpenBoxM);
    useOutsideClicker(selectRefD, setOpenBoxD);
    useOutsideClicker(selectRefY, setOpenBoxY);
    useOutsideClicker(selectLookingBoxRef, setOpenLookingBox);
    useOutsideClicker(selectEmailRef, setOpenEmailList);

    const trimEmail = formDataRegister?.email?.split('@')[0];

    const onSelectDomain = (e: React.MouseEvent<HTMLDivElement>, item: string) => {
        e.stopPropagation();
        setFormDataRegister({ ...formDataRegister, ...{ email: `${trimEmail}@${item}` } });
        setOpenEmailList(false);
        if (filterErrorFields) filterErrorFields(REGISTER.FIELD_EMAIL);
    };

    const onChangeEmail = (value: string) => {
        setFormDataRegister({ ...formDataRegister, ...{ email: value } });
        filterErrorFields(REGISTER.FIELD_EMAIL);

        if (checkEmailPattern(value) || !value?.length) {
            setOpenEmailList(false);
        } else {
            setOpenEmailList(true);
        }
    };

    const goToSignInBlock = () => {
        openSignInBlock();

        if (closeRegisterModal && modalIsActiveRegister) {
            closeRegisterModal();
        }
    };

    return (
        <form className={styles.first_block_registr_form} onSubmit={onRegister}>
            <div className={styles.first_block_registr_form_text_fieldset}>
                <div className={styles.first_block_registr_form_text_column}>
                    <div className={styles.first_block_registr_form_text_row}>
                        <input
                            type="text"
                            name="name"
                            data-testid="name"
                            className={`${styles.first_block_registr_form_input} ${styles[`${checkErrorField(errorFields, REGISTER.FIELD_NAME)}`]}`}
                            maxLength={LENGTH.MAX_NAME}
                            value={formDataRegister.name}
                            onChange={(e) => {
                                setFormDataRegister({
                                    ...formDataRegister,
                                    ...{ name: e.target.value },
                                });
                                filterErrorFields(REGISTER.FIELD_NAME);
                            }}
                            placeholder="What is your name?"
                        />
                    </div>
                    <div
                        className={`${styles.first_block_registr_form_looking_row} ${openLookingBox ? styles.open : ''} ${styles.selected}`}
                        onClick={() => setOpenLookingBox(!openLookingBox)}
                    >
                        <div className={styles.first_block_registr_form_looking_title}>
                            {DICTIONARY_MAIN_PAGE.REGISTRATION_FORM_GENDER_TITLE}
                        </div>
                        <div className={styles.main_select_item_wrap} data-testid="gender">
                            <div ref={selectLookingBoxRef} className={styles.main_select_item}>
                                <div className={styles.main_select_item_head}>
                                    {formDataRegister?.gender}
                                </div>
                                <div className={styles.main_select_item_list}>
                                    <div
                                        className={styles.main_select_item_list_inner}
                                        data-testid="select-list"
                                    >
                                        {REGISTER.GENDER_ARRAY.map((item, key) => (
                                            <React.Fragment key={key}>
                                                <input
                                                    type="radio"
                                                    name="main_dob_ll"
                                                    id={`main_dob_ll_${key}`}
                                                    data-testid={`value-${key}`}
                                                />
                                                <label
                                                    htmlFor={`main_dob_ll_${key}`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setFormDataRegister({
                                                            ...formDataRegister,
                                                            ...{ gender: item?.title },
                                                        });
                                                        setOpenLookingBox(false);
                                                    }}
                                                >
                                                    {item?.title}
                                                </label>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className={`${styles.first_block_registr_form_text_row} ${styles.with_email_help} ${openEmailList ? styles.opened : ''}`}
                    ref={selectEmailRef}
                >
                    <input
                        type="email"
                        className={`${styles.first_block_registr_form_input} ${styles[`${checkErrorField(errorFields, REGISTER.FIELD_EMAIL)}`]}`}
                        name="email"
                        data-testid="email"
                        value={formDataRegister?.email}
                        onChange={(e) => onChangeEmail(e.target.value)}
                        placeholder="What is your email?"
                    />
                    <div className={styles.first_block_registr_form_emails_list_wrap}>
                        <div className={styles.first_block_registr_form_emails_list}>
                            {REGISTER.DOMAIN_ARRAY.map((item, index) => (
                                <div
                                    key={`${index}_${item}`}
                                    className={styles.first_block_registr_form_emails_list_item}
                                    onClick={(e) => onSelectDomain(e, item)}
                                >
                                    {trimEmail}@{item}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={styles.first_block_registr_form_text_row}>
                    <input
                        type={isPasswordHidden ? 'password' : 'text'}
                        data-testid="password"
                        className={`${styles.first_block_registr_form_input} ${styles.first_block_registr_form_password} ${styles[`${checkErrorField(errorFields, REGISTER.FIELD_PASSWORD)}`]}`}
                        value={formDataRegister?.password}
                        onChange={(e) => {
                            setFormDataRegister({
                                ...formDataRegister,
                                ...{ password: e.target.value },
                            });
                            filterErrorFields(REGISTER.FIELD_PASSWORD);
                        }}
                        placeholder="Password (8+ characters)"
                    />
                    <button
                        type="button"
                        className={`${styles.first_block_registr_password_show} ${!isPasswordHidden ? styles.active : ''}`}
                        onClick={() => setPasswordHidden(!isPasswordHidden)}
                    />
                </div>
                <div className={styles.first_block_registr_dob_row_wrap}>
                    <div className={styles.first_block_registr_dob_row}>
                        <div
                            className={`${styles.main_select_item_wrap} ${styles[`${checkErrorField(errorFields, REGISTER.FIELD_MONTH)}`]}`}
                            ref={selectRefM}
                            data-testid="month"
                        >
                            <div
                                className={`${styles.main_select_item} ${openBoxM ? styles.open : ''} ${formDataRegister?.bMonth ? styles.selected : ''}`}
                                onClick={() => setOpenBoxM(!openBoxM)}
                            >
                                <div className={styles.main_select_item_head}>
                                    {+formDataRegister?.bMonth > 0
                                        ? REGISTER.ARRAY_MONTH[formDataRegister?.bMonth - 1]
                                        : DICTIONARY_MAIN_PAGE.REGISTRATION_FORM_DOB_SELECT_MONTH}
                                </div>
                                <div className={styles.main_select_item_list}>
                                    <div
                                        className={styles.main_select_item_list_inner}
                                        data-testid="select-list"
                                    >
                                        {REGISTER.ARRAY_MONTH.map((item, key) => (
                                            <React.Fragment key={key}>
                                                <input
                                                    type="radio"
                                                    name="main_dob_mm"
                                                    id={`main_dob_mm_${key}`}
                                                    data-testid={`value-${key}`}
                                                />
                                                <label
                                                    htmlFor={`main_dob_mm_${key}`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        onBirthdayChange(+key + 1, 'bMonth');
                                                        setOpenBoxM(false);
                                                        filterErrorFields(REGISTER.FIELD_MONTH);
                                                    }}
                                                >
                                                    {item}
                                                </label>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`${styles.main_select_item_wrap} ${styles[`${checkErrorField(errorFields, REGISTER.FIELD_DAY)}`]}`}
                            ref={selectRefD}
                            data-testid="day"
                        >
                            <div
                                className={`${styles.main_select_item} ${openBoxD ? styles.open : ''} ${formDataRegister?.bDay ? styles.selected : ''}`}
                                onClick={() => setOpenBoxD(!openBoxD)}
                            >
                                <div className={styles.main_select_item_head}>
                                    {+formDataRegister?.bDay > 0
                                        ? formDataRegister?.bDay
                                        : DICTIONARY_MAIN_PAGE.REGISTRATION_FORM_DOB_SELECT_DAY}
                                </div>
                                <div className={styles.main_select_item_list}>
                                    <div
                                        className={styles.main_select_item_list_inner}
                                        data-testid="select-list"
                                    >
                                        {getDaysAmount(
                                            formDataRegister?.bMonth,
                                            formDataRegister?.bYear,
                                        ).map((item, key) => (
                                            <React.Fragment key={key}>
                                                <input
                                                    type="radio"
                                                    name="main_dob_dd"
                                                    id={`main_dob_dd_${key}`}
                                                    data-testid={`value-${key}`}
                                                />
                                                <label
                                                    htmlFor={`main_dob_dd_${key}`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        onBirthdayChange(item, 'bDay');
                                                        setOpenBoxD(false);
                                                        filterErrorFields(REGISTER.FIELD_DAY);
                                                    }}
                                                >
                                                    {item}
                                                </label>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`${styles.main_select_item_wrap} ${styles[`${checkErrorField(errorFields, REGISTER.FIELD_YEAR)}`]}`}
                            data-testid="year"
                        >
                            <div
                                ref={selectRefY}
                                className={`${styles.main_select_item} ${openBoxY ? styles.open : ''} ${formDataRegister?.bYear ? styles.selected : ''}`}
                                onClick={() => setOpenBoxY(!openBoxY)}
                            >
                                <div className={styles.main_select_item_head}>
                                    {+formDataRegister?.bYear > 0
                                        ? formDataRegister?.bYear
                                        : DICTIONARY_MAIN_PAGE.REGISTRATION_FORM_DOB_SELECT_YEAR}
                                </div>
                                <div className={styles.main_select_item_list}>
                                    <div
                                        className={styles.main_select_item_list_inner}
                                        data-testid="select-list"
                                    >
                                        {getArrayOfNumbersYear(
                                            REGISTER.START_YEAR,
                                            REGISTER.END_YEAR,
                                        ).map((item, key) => (
                                            <React.Fragment key={key}>
                                                <input
                                                    type="radio"
                                                    name="main_dob_yy"
                                                    id={`main_dob_yy_${key}`}
                                                    data-testid={`value-${key}`}
                                                />
                                                <label
                                                    htmlFor={`main_dob_yy_${key}`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        onBirthdayChange(item, 'bYear');
                                                        // setFormDataRegister({...formDataRegister, ...{bYear: item}});
                                                        setOpenBoxY(false);
                                                        filterErrorFields(REGISTER.FIELD_YEAR);
                                                    }}
                                                >
                                                    {item}
                                                </label>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`${styles.first_block_registr_form_policy_row} ${styles[`${checkErrorField(errorFields, REGISTER.FIELD_AGREE)}`]}`}
                data-testid="policy"
            >
                <input
                    type="checkbox"
                    id="form_policy_check"
                    checked={formDataRegister.agree}
                    onChange={() => {}}
                />
                <label
                    htmlFor="form_policy_check"
                    onClick={() => {
                        setFormDataRegister({
                            ...formDataRegister,
                            ...{ agree: !formDataRegister.agree },
                        });
                        filterErrorFields(REGISTER.FIELD_AGREE);
                    }}
                />
                <div className={styles.first_block_registr_form_policy_text}>
                    <span>
                        By clicking “Register now!” or “Sign in with Google” I hereby verify that I
                        am over 18 years of age and have read and agree to the:{' '}
                    </span>
                    <div onClick={() => openTermModal(MENU.LINK_TERMS)}>Terms and Conditions,</div>
                    <div onClick={() => openTermModal(MENU.LINK_PRIVACY)}>Privacy Policy,</div>
                    <div onClick={() => openTermModal(MENU.LINK_COOKIE)}>Cookies Policy,</div>
                    <div onClick={() => openTermModal(MENU.LINK_RETURN_REFUND)}>
                        Refund Policy,{' '}
                    </div>
                    <div onClick={() => openTermModal(MENU.LINK_STANDARDS)}>
                        Community Standards{' '}
                    </div>
                    <span>
                        and I also agree to receive any emails relating to {SITE.copyrightsText}. Do
                        not sell my personal information
                    </span>
                </div>
            </div>
            {/*<div className={styles.error_text}>Field is to be completed</div>*/}
            {/* <div className={styles.first_block_registr_form_tablet_social}>*/}
            {/*    <span>Sign up with</span>*/}
            {/*    <div className={styles.first_block_registr_form_tablet_social_item}>*/}
            {/*        <img src={newMainIMG.images['new_google_login.svg']} alt=""/>*/}
            {/*    </div>*/}
            {/*    <div className={styles.first_block_registr_form_tablet_social_item}>*/}
            {/*        <img src={newMainIMG.images['new_fb_login.svg']} alt=""/>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {hasCaptcha && (
                <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey={REGISTER.RECAPTCHA_SITE_KEY}
                    badge="bottomleft"
                />
            )}

            <button
                type="submit"
                className={styles.first_block_registr_form_find_btn}
                data-testid="submit-btn"
            >
                <span className={styles.first_block_registr_form_find_btn_text}>Register now!</span>
            </button>

            <div className={styles.registr_form_footer}>
                <p className={styles.registr_form_footer_text}>Already have an account?</p>
                <button
                    type="button"
                    className={styles.registr_form_footer_btn}
                    onClick={goToSignInBlock}
                >
                    <span className={styles.registr_form_footer_btn_text}>Sign in</span>
                </button>
            </div>
        </form>
    );
}
